<template>
  <ig-page-base
    :is-boxed="true"
    :loading="loading"
    :title="$t('pageEmailConfirmation.title')"
    class="page-email-confirmation__box">
    <div>
      <b-message :type="`is-${confirmed ? 'success' : 'danger'}`">
        <p class="field">
          {{ $t(`pageEmailConfirmation.${validationType}.title`) }}
        </p>
      </b-message>

      <p
        class="has-text-centered page-email-confirmation__message"
        v-html="$t(`pageEmailConfirmation.${validationType}.message`)" />

      <div class="field">
        <b-button
          class="is-uppercase has-text-weight-bold is-uppercase"
          type="is-primary"
          @click="goToManage"
          expanded>
          {{ $t(`pageEmailConfirmation.${validationType}.action`) }}
        </b-button>
      </div>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase } from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('emailConfirmation');

export default Vue.extend({
  name: 'page-success',
  components: {
    IgPageBase,
  },
  data() {
    return {
      confirmed: false,
    };
  },
  computed: {
    ...mapState([
      'loading',
    ]),
    validationType() {
      return this.confirmed ? 'success' : 'error';
    },
  },
  methods: {
    ...mapActions([
      'checkToken',
    ]),
    goToManage() {
      window.location.href = process.env.VUE_APP_MANAGE_URL;
    },
  },
  beforeMount() {
    const { token } = this.$route.params || {};

    this.checkToken(token).then((ok) => {
      if (ok) {
        this.confirmed = true;
        setTimeout(this.goToManage, 4000);
      } else {
        this.$buefy.toast.open({
          message: this.$t('pageEmailConfirmation.invalidToken'),
          type: 'is-danger',
          position: 'is-bottom',
        });
      }
    });
  },
});
</script>
<style lang="scss">
.page-email-confirmation {
  &__box {
    .box.ig-box {
      max-width: 450px;
    }
  }

  &__message {
    margin-bottom: 1.5rem;
  }
}
</style>
